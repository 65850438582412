










































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { apiUserDetail, apiUserSetInfo } from '@/api/user/user'
import LsDialog from '@/components/ls-dialog.vue'
import LsUserChange from '@/components/user/ls-user-change.vue'
import LsLabelChange from '@/components/user/ls-label-change.vue'
import LsLevelChange from '@/components/user/ls-level-change.vue'
import LsInvitationChange from '@/components/user/ls-invitation-change.vue'
import PopoverInputUser from '@/components/user/popover-input-user.vue'
@Component({
    components: {
        LsDialog,
        LsUserChange,
        LsLabelChange,
        LsLevelChange,
        PopoverInputUser,
        LsInvitationChange
    }
})
export default class UserDetails extends Vue {
    /** S Data **/
    user_id = ''
    isShowWallet = 1 // 1-钱包资产 0-分销

    // 用户信息
    user_info = {
        id: '', // 用户id
        sn: '', // 用户编号
        nickname: '', // 用户昵称
        avatar: '', // 用户头像
        sex: '', // 性别 0-未知 1-男 2-女
        code: '', // 邀请码
        level_name: '', // 等级名称
        create_time: '', // 创建时间
        login_time: '', // 最后的登录时间
        disable: '', // 是否禁用：1-是；0-否（可用于显示加入黑名单和放出黑名单按钮）
        labels: '', // 用户标签
        leader_inviter: '', // 上级邀请人
        fans: '', // 团队人数
        total_user_money: '', // 钱包金额
        money: '', // 可用金额
        earnings: '', // 可提现金额
        user_integral: '', // 积分
        count_num: '', // 优惠券数量
        inviter: {
            name: '', // 邀请人名称
            num: '' // 我邀请的人数
        },
        first_leader_info: {
            name: '' // 上级分销商名称
        }
    }
    // 交易信息
    transaction = {
        total_order_amount: '', // 	成交订单数
        total_order_num: '', // 	消费金额
        customer_price: ' ', // 	客单价
        lately_order_time: '' // 	上次消费时间
    }

    // 验证规则
    userRules = {
        // name: [{
        // 	required: true,
        // 	message: '请输入规则名称',
        // 	trigger: 'blur'
        // }],
    }
    transactionRules = {}

    /** E Data **/

    /** S Methods **/
    // 获取用户信息
    userDetail() {
        apiUserDetail({
            user_id: this.user_id
        })
            .then((res: any) => {
                this.user_info = res.user_info
                this.transaction = res.transaction
            })
            .catch((res: any) => {})
    }
    // 修改用户信息
    userSetInfo(val: string, type: string) {
        apiUserSetInfo({
            user_id: this.user_id,
            field: type,
            value: val
        })
            .then((res: any) => {
                this.userDetail()
            })
            .catch((res: any) => {})
    }

    // 跳转邀请人数页面
    toInvitationList(item: any) {
        this.$router.push({
            path: '/user/invitation_list',
            query: {
                id: item.id
            }
        })
    }

    // 冻结会员
    handleFrozen() {
        let { disable } = this.user_info as any
        disable = disable == 0 ? 1 : 0
        this.userSetInfo(disable, 'disable')
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        if (query.id) {
            this.user_id = query.id
        }

        this.userDetail()
    }
    /** E Life Cycle **/
}
