

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiUserSetAdjustUserWallet } from '@/api/user/user'
@Component({
    components: {}
})
export default class LsUserChange extends Vue {
    @Prop() value?: number
    @Prop() type?: number // 变动类型：1-用户金额；2-可提现金额；3-积分
    @Prop() userId?: number // 用户id
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        valueRef: any
    }
    form = {
        user_id: '', // 用户id
        type: 1, // 变动类型：1-用户金额；2-可提现金额；3-积分
        action: 1, // 调整类型：0-减少；1-增加
        num: 0, // 调整数量
        remark: '', // 备注
        value: '' // 初始值
    }
    typeName = '' // 变动类型名称

    // 表单验证
    valueRules = {
        // num: [{
        // 	required: true,
        // 	message: '请输入',
        // 	trigger: 'blur'
        // }, {
        // 	type: 'number',
        // 	message: '请输入大于0的数字',
        // 	trigger: 'blur'
        // }],
    }
    // 修改后的值
    get lastValue(): number {
        let total:any = this.value
        if (this.form.action == 1) {
            total = Number(this.form.value) + this.form.num * 1
        } else {
            total = Number(Number(this.form.value) - this.form.num * 1).toFixed(2)
        }
        return total
    }
    /** E Data **/

    @Watch('userId', {
        immediate: true
    })
    getuserId(val: any) {
        // 初始值
        //this.form.value = val
        this.$set(this.form, 'user_id', val)
    }

    @Watch('value', {
        immediate: true
    })
    getValue(val: any) {
        // 初始值
        //this.form.value = val
        this.$set(this.form, 'value', val)
    }

    @Watch('type', {
        immediate: true
    })
    getType(val: any) {
        // 变动类型：1-用户金额；2-可提现金额；3-积分
        if (val == 1) {
            this.typeName = '金额'
            this.$set(this.form, 'type', val)
        } else if (val == 2) {
            this.typeName = '可提现金额'
            this.$set(this.form, 'type', val)
        } else if (val == 3) {
            this.typeName = '积分'
            this.$set(this.form, 'type', val)
        }
    }

    /** S Methods **/
    // 调整用户钱包
    updateUserAdjustUserWallet() {
        const num = this.form.num * 1
        if (num <= 0) {
            return this.$message.error('请输入大于0的数字')
        }
        apiUserSetAdjustUserWallet(this.form)
            .then((res: any) => {
                // 重新获取用户详情
                this.$emit('refresh')
                this.visible = false
            })
            .catch((res: any) => {
                this.visible = false
            })
    }

    onTrigger() {
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false

        // 重制表单内容
        this.$set(this.form, 'num', 0)
        this.$set(this.form, 'remark', '')
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
