












































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiUserSearchList, apiUserSetInfo } from '@/api/user/user'
@Component({
    components: {}
})
export default class LsLevelChange extends Vue {
    @Prop() value?: number
    @Prop() userId?: number // 用户id
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        valueRef: any
    }
    form = {
        user_id: '', // 用户id
        level: '', // 用户等级
        remark: '', // 备注
        value: '' // 初始值
    }
    // 选中用户标签
    labelValue = []
    // 用户选择框数据
    userLevelList = []

    // 表单验证
    valueRules = {
        level: [
            {
                required: true,
                message: '请选择用户等级',
                trigger: 'change'
            }
        ]
    }
    // 修改后的值
    get lastValue(): string {
        //
        let total: any = [{ name: '' }]
        // let total =((this.userLevelList as any)[this.form.level])
        //
        total = this.userLevelList.filter((item: any) => {
            //
            return item?.id == this.form.level
        })
        const res = total.map((item: any) => item.name)

        return res.toString()
    }
    /** E Data **/

    @Watch('userId', {
        immediate: true
    })
    getuserId(val: any) {
        // 初始值
        //this.form.value = val
        this.$set(this.form, 'user_id', val)
    }

    @Watch('value', {
        immediate: true
    })
    getValue(val: any) {
        // 初始值
        // this.form.value = val
        this.$set(this.form, 'value', val)
    }

    /** S Methods **/
    // 获取多选框列表
    getUserSearchList() {
        apiUserSearchList().then((res: any) => {
            this.userLevelList = res.user_level_list
        })
    }

    // 设置用户等级
    changeUserLevel() {
        this.$refs.valueRef.validate((valid: any) => {
            if (!valid) {
                return
            }

            // if(this.form.level == '') {
            // 	return this.$message.error("请选择用户等级")
            // }

            apiUserSetInfo({
                user_id: this.form.user_id,
                field: 'level',
                value: this.form.level
            })
                .then((res: any) => {
                    this.$emit('refresh')
                })
                .catch((res: any) => {})
            this.visible = false
            this.form.remark = ''
        })
    }

    onTrigger() {
        this.getUserSearchList()
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false

        // 重制表单内容
        this.form.remark = ''
        this.form.level = ''
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
