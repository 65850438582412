import { render, staticRenderFns } from "./ls-user-change.vue?vue&type=template&id=3fe9d820&scoped=true&"
import script from "./ls-user-change.vue?vue&type=script&lang=ts&"
export * from "./ls-user-change.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe9d820",
  null
  
)

export default component.exports